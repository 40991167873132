<template>
  <CommonPage id="answer" title="Insight&Book Online" classs="answer">
    <section class="home-content pc_padding">
      <div class="banner">
        <div class="answer-banner-t-row">
          <div class="answer-banner-txt">
            {{ $t(answerCode) }}
          </div>
        </div>
        <img src="@/assets/home-banner.png" alt="">
        <img src="@/assets/book_pc.png" class="book" alt="">

        <div class="answer-btn" @click="refresh()">
          Ask again
        </div>
      </div>

      <div v-if="Object.keys(translations).length > 0" class="answer-row mb15">
        <AdComponent
					ref="ads-5"
					class="m-b-40 w90"
					:ads="adsensConfig.ad5"
					:showDebug="showDebug" />
        <div v-for="(translation, lang) in translations" :key="lang">
          {{ translation }}
        </div>
      </div>
			<AdComponent
				ref="ads-6"
				class="m-b-40"
				:ads="adsensConfig.ad6"
				:showDebug="showDebug" />
      <div class="home-intro-title">Something may help</div>
			<div
				class="home-intro-row"
				style="background: #19183b; padding-left: 0; padding-right: 0; padding-top: 0">
        <div class="time-page-card-row">
						<div
							v-for="(data, i) in card"
							:key="i"
							class="time-page-card-item"
							@click="goto(data.routename)">
							<div class="time-page-card-image">
								<img
									:src="data.img"
									:alt="data.title" />
							</div>
							<div class="time-page-card-content">
								<div class="time-page-card-title">
									{{ data.title }}
								</div>
								<div class="time-page-card-desc">
									{{ data.desc }}
								</div>
							</div>
							<div class="time-page-card-btn">Read more</div>
						</div>
					</div>
			</div>
    </section>
  </CommonPage>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
import CommonPage from '@/pages/components/pc_common.vue';
import i18n from '@/i18n';
import AdComponent from '@/pages/components/AdComponent.vue'

import '@/css/home.scss';

export default {
  name: 'HomeMain',
  components: {
    CommonPage,
    AdComponent
  },
  mixins: [common],
  data () {
    return {
      languages: ['ar', 'zh', 'de', 'en', 'es', 'fr', 'hi', 'ja', 'ko', 'pt', 'ru'],
      translations: {},
      answerCode: null,
			card: [
				{
					img: require('@/assets/i1.png'),
					title: '"Insight&Book Online": Transformative Success Stories',
					desc: "In today's fast-paced, information-saturated world, where social media is omnipresent, many individuals find themselves feeling lost and anxious in their pursuit of success. However, 'Insight&Book Online' has served as a unique tool to guide countless individuals towards clarity. It is more than just a guide; it is a transformative tool for a lifestyle shift, enabling people to pursue happiness and achievement with a stronger sense of purpose. ",
					routename: 'blog1',
				},
				{
					img: require('@/assets/i2.png'),
					title: "Insight&Book Online: A Beacon of Wisdom and Guidance for Life's Crucial Decisions",
					desc: "Insight&Book Online is not just a collection of wisdom but a guiding light in the lives of many readers. Through real-life customer stories, we see how this book has helped individuals make the right decisions at pivotal moments, whether in career choices, emotional decisions, or repairing family relationships. When Li Lei faced a career dilemma, he turned to Insight&Book Online and found a simple yet profound message that helped him choose the right path. Similarly, Ms. Zhang, through the book’s advice, repaired her strained relationship with her husband. These success stories show that Insight&Book Online is more than just a book; its concise and deep insights have helped countless readers navigate their life's challenges.",
					routename: 'blog2',
				},
				{
					img: require('@/assets/i3.png'),
					title: 'The Accuracy of Insight&Book Online: Unveiling the Power of this Mysterious Book',
					desc: "Insight&Book Online is a fascinating and mysterious book that has captured the attention of many seeking guidance in life. From personal dilemmas and emotional uncertainties to career choices and everyday decisions, Insight&Book Online provides brief responses that help individuals navigate life’s uncertainties. But how accurate are its predictions? Why do so many claim that this book leads them in the right direction? In this article, we will explore the accuracy of Insight&Book Online and analyze its unique role in psychology, philosophy, and personal growth.",
					routename: 'blog3',
				},
				{
					img: require('@/assets/i4.png'),
					title: 'How to Interpret Insight&Book Online – Unveiling the Wisdom Behind It',
					desc: "Insight&Book Online is a unique tool with a mysterious allure, frequently used by those seeking answers to life's questions. Whether it's emotional dilemmas, career decisions, or everyday uncertainties, the book provides brief yet impactful responses that guide readers toward reflection and self-discovery. Its charm doesn't just lie in the simplicity of its answers but in how one interprets and applies these insights. This article will explore how to interpret Insight&Book Online, using SEO-optimized keywords like “Insight&Book Online meaning,” “how accurate is Insight&Book Online,” and “how to use Insight&Book Online,” while also demonstrating its role in fostering personal growth and decision-making.",
					routename: 'blog4',
				},
				{
					img: require('@/assets/i5.png'),
					title: 'Industry Insight Report: Trends and Insights of "Insight&Book Online"',
					desc: "In today's rapidly evolving knowledge economy, the ability to acquire and interpret information is becoming increasingly important. Particularly in areas such as self-improvement, mental health, and interpersonal relationships, people's craving for spiritual nourishment has made various psychology and philosophy books popular. 'Insight&Book Online' as a classic work that explores a variety of life questions, has gained widespread attention for its profound wisdom and practical guidance. This report will analyze current industry trends based on data related to 'Insight&Book Online' and showcase our company’s expertise in this field",
					routename: 'blog5',
				},
				{
					img: require('@/assets/i6.png'),
					title: 'Insight&Book Online: Common Questions and Unique Responses, and Why People Are Drawn to Divination from a Psychological Perspective',
					desc: "Insight&Book Online is a fascinating and interactive divination tool that provides brief yet insightful responses to common life questions. It not only offers philosophical guidance but also draws people seeking clarity and inner reflection. In this article, we will explore Insight&Book Online from two angles: first, by listing some common questions and showcasing the book’s unique and detailed responses; second, by analyzing from a psychological perspective why people are drawn to divination books like Insight&Book Online and how these tools help them better understand their decisions.",
					routename: 'blog6',
				},

			],
    }
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
      "adsensConfig"

    ])
  },
  async created () {
    await this.initCode();
    this.loadTranslations();
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
    await this.loadAdSenseScript();  // 重新加载广告脚本
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
  methods: {
    //广告相关start
    async loadAdSenseScript () {
      // 其他需要在初次加载时执行的操作
      // 先检查广告是否已经加载
      if (window.adsbygoogle && window.adsbygoogle.loaded) { // 检查广告是否已经加载
        console.log("Adsense script already loaded.");
        this.$eventrack('adscript_loaded', 'expose');
        this.loadAdWithDelay()
        return; // 如果已加载，直接返回
      }
      await this.loadScriptConditionally();
    },
    loadScriptConditionally () {
      console.log(this.adsensConfig);
      // 判断广告脚本URL是否存在
      if (!this.adsensConfig?.scriptUrl) {
        console.log('广告脚本的URL不存在,终止加载广告外链');
        this.$eventrack('no_adscript_config', 'expose');
        return;
      }
      // 检查该脚本是否已经被添加
      const existingScript = document.querySelector(`script[src="${this.adsensConfig.scriptUrl}"]`);
      if (existingScript) {
        this.$eventrack('adscript_exist', 'expose');
        console.log('脚本已存在，无需重新添加');
        return;
      }

      console.log('准备插入脚本');
      const script = document.createElement('script');
      script.src = this.adsensConfig?.scriptUrl;
      script.crossOrigin = 'anonymous';
      script.async = true;

      const header = document.getElementsByTagName('head')[0];
      header.appendChild(script);

      this.$eventrack('adscript_add_success', 'expose');
      script.onload = this.loadAdWithDelay.bind(this); // 使用 bind 确保 this 指向正确
      console.log('脚本插入完成,加载完成,执行加载插入广告及监听操作');

    },
    loadAdWithDelay () {
      setTimeout(() => {
        this.displayAd();
      }, 500);
    },
    async displayAd () {
      await this.$nextTick();

      // 获取所有 ads 元素的 refs，过滤掉非广告的 ref
      const adsElements = Object.entries(this.$refs)
        .filter(([key]) => key.startsWith('ads-')) // 只选择以 'ads-' 开头的 refs
        .flatMap(([, ref]) => ref); // 展开并获取所有元素

      if (!window.adsbygoogle || !window.adsbygoogle.loaded) {
        console.log("Adsense script not loaded yet, delaying ad display.");
        setTimeout(this.displayAd, 500); // 延迟再次尝试
        return;
      }

      console.log(adsElements); // 检查是否包含 <ins> 标签
      adsElements.forEach(ad => {
        console.log(ad); // 输出每个广告元素
        console.log('ready to push');
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      });
    },
    //广告相关end
    initCode () {
      this.answerCode = `A${Math.floor(Math.random() * 269) + 2}`;
    },
    refresh () {
      this.initCode();
      this.loadTranslations(); // 调用 loadTranslations 以更新所有翻译
      console.log('refresh');
    },
    loadTranslations () {
      console.log(this.answerCode);
      this.languages.forEach((lang) => {
        this.$set(this.translations, lang, i18n.messages[lang][this.answerCode] || `No translation for ${lang}`);
      });
      console.log(this.translations); // 打印 translations 以确认更新
    },
    goto (name) {
      this.$router.push({ name: name });
    },
  }
}
</script>
